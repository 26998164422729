import React from 'react';
import PropTypes from 'prop-types';
import { getSafe } from '../../../utils/getSafe';
import './FieldSelect.scss';

const FieldSelect = ({
    inputName,
    handleChange,
    values,
    touched,
    errors,
    className = '',
    field,
    options = '',
    page = '',
    isDisabled = false,
    handleBlur = () => {},
}) => {
    const value = values[inputName];
    const { placeholder, isRequired } = field || {};
    return (
        <>
            <select
                id={`${page}${inputName}`}
                name={inputName}
                value={getSafe(() => value.value, '')}
                onChange={handleChange}
                className={`react-select-container field-select ${className}`}
                aria-required={isRequired}
                onBlur={handleBlur}
                disabled={isDisabled}
                aria-disabled={isDisabled}
                aria-describedby={`${page}${inputName}_error`}
            >
                <option value="" hidden={true} disabled={true}></option>
                {options &&
                    options.map((option, i) => (
                        <option key={i} value={option.value}>
                            {option.label}
                        </option>
                    ))}
            </select>
            <label
                htmlFor={`${page}${inputName}`}
                className={`floating-label ${getSafe(() => value.value, '') !== '' ? ' top' : ''}`}
            >
                {placeholder} {isRequired ? '*' : ''}
            </label>
            <span className="form-error" role="alert" id={`${page}${inputName}_error`}>
                {touched[inputName] && errors[inputName] && <>{errors[inputName]}</>}
            </span>
        </>
    );
};

FieldSelect.propTypes = {
    inputName: PropTypes.string,
    handleChange: PropTypes.func,
    values: PropTypes.object,
    field: PropTypes.object,
    touched: PropTypes.object,
    errors: PropTypes.object,
    className: PropTypes.string,
    options: PropTypes.array,
    isSearchable: PropTypes.bool,
    page: PropTypes.string,
    handleBlur: PropTypes.func,
    isDisabled: PropTypes.bool,
};

export default FieldSelect;
