import React, { Component } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import { getSafe } from '../utils/getSafe';
import '../styles/contact.scss';
import ContactForm from '../components/ContactForm/ContactForm';

export default class ContactTemplate extends Component {
    state = {
        showFromSubmissionMessage: false,
        showFormFooter: true,
    };

    componentDidMount() {
        document.body.classList.add('colored-nav');
    }
    componentWillUnmount() {
        document.body.classList.remove('colored-nav');
    }

    renderContactText = ({ title, address, supportInfo }) => (
        <div>
            <h1
                dangerouslySetInnerHTML={{
                    __html: title,
                }}
            />
            <p
                dangerouslySetInnerHTML={{
                    __html: address,
                }}
            />
            <p
                dangerouslySetInnerHTML={{
                    __html: supportInfo,
                }}
            />
        </div>
    );
    render() {
        const { data } = this.props;
        const info = {
            title: getSafe(() => data.nodeContact.field_contact_title),
            address: getSafe(() => data.nodeContact.field_address.value),
            supportInfo: getSafe(() => data.nodeContact.field_support.value),
        };
        const regions = getSafe(() => data.regions.edges, []).reduce((regionsData, data) => {
            let countries = getSafe(() => data.node.relationships.field_region, []);
            return [...regionsData, ...countries];
        }, []);
        const first_name = getSafe(() => data.nodeContact.relationships.field_contact_form_fields)
            ? data.nodeContact.relationships.field_contact_form_fields.filter(
                  itemForm => itemForm.field_name === 'first_name'
              )
            : [];
        const last_name = getSafe(() => data.nodeContact.relationships.field_contact_form_fields)
            ? data.nodeContact.relationships.field_contact_form_fields.filter(
                  itemForm => itemForm.field_name === 'last_name'
              )
            : [];
        const email = getSafe(() => data.nodeContact.relationships.field_contact_form_fields)
            ? data.nodeContact.relationships.field_contact_form_fields.filter(
                  itemForm => itemForm.field_name === 'email'
              )
            : [];
        const country_of_residence = getSafe(() => data.nodeContact.relationships.field_contact_form_fields)
            ? data.nodeContact.relationships.field_contact_form_fields.filter(
                  itemForm => itemForm.field_name === 'country_of_residence'
              )
            : [];
        const date_of_birth = getSafe(() => data.nodeContact.relationships.field_contact_form_fields)
            ? data.nodeContact.relationships.field_contact_form_fields.filter(
                  itemForm => itemForm.field_name === 'date_of_birth'
              )
            : [];
        const subject = getSafe(() => data.nodeContact.relationships.field_contact_form_fields)
            ? data.nodeContact.relationships.field_contact_form_fields.filter(
                  itemForm => itemForm.field_name === 'subject'
              )
            : [];
        const message = getSafe(() => data.nodeContact.relationships.field_contact_form_fields)
            ? data.nodeContact.relationships.field_contact_form_fields.filter(
                  itemForm => itemForm.field_name === 'message'
              )
            : [];
        const submitTo = getSafe(() => data.nodeContact.relationships.field_contact_form_fields)
            ? data.nodeContact.relationships.field_contact_form_fields.filter(
                  itemForm => itemForm.field_name === 'submitTo'
              )
            : [];

        const form = {
            first_name: first_name.length ? first_name[0] : data.contactJson.form.first_name,
            last_name: last_name.length ? last_name[0] : data.contactJson.form.last_name,
            date_of_birth: submitTo.length ? date_of_birth[0] : data.contactJson.form.date_of_birth,
            email: email.length ? email[0] : data.contactJson.form.email,
            subject: subject.length ? subject[0] : data.contactJson.form.subject,
            message: message.length ? message[0] : data.contactJson.form.message,
            submitTo: submitTo.length ? submitTo[0] : data.contactJson.form.submitTo,
            btn_label: data.nodeContact.field_submit_label_button
                ? data.nodeContact.field_submit_label_button
                : 'Submit',
            legalCopy: getSafe(() => data.nodeContact.field_legal_clause.value),
            fields_required_copy: getSafe(() => data.nodeContact.fields_required_copy.value),
            personal_data_copy: getSafe(() => data.nodeContact.personal_data_copy.value),
            agree_get_newsletter_copy: getSafe(() => data.nodeContact.agree_get_newsletter_copy.value),
            signupContactErrorMessage: getSafe(() => data.nodeContact.signup_contact_error_message.value),
            submissionMessage: getSafe(() => data.nodeContact.submission_message.value),
            country_of_residence: country_of_residence.length
                ? country_of_residence[0]
                : data.contactJson.form.country_of_residence,
            regions: regions,
        };
        const title = getSafe(() => data.nodeContact.field_seo_metatag.title, '');
        const description = getSafe(() => data.nodeContact.field_seo_metatag.description, '');
        const keywords = getSafe(() => data.nodeContact.field_seo_metatag.keywords, null);
        const newsLetterConfig = getSafe(() => data.nodeNewsletterForm, []);
        const langKey = this.props.pageContext.langcode;

        let allNewsletterFormSignUpCopy = getSafe(() => data.allNewsletterFormSignUpCopy, []);
        if (allNewsletterFormSignUpCopy.edges) {
            allNewsletterFormSignUpCopy = allNewsletterFormSignUpCopy.edges.map(function(elem) {
                return {
                    copy: getSafe(() => elem.node.field_signing_up_copy_2.value, ''),
                    copy_2: getSafe(() => elem.node.field_signing_up_copy_block_2.value, ''),
                    lang: elem.node.relationships.field_language.field_language_code,
                    display_sign_up_checkbox: getSafe(() => elem.node.field_display_sign_up_checkbox, false),
                };
            });
        }

        return (
            <Layout
                langKey={langKey}
                shopMenu={getSafe(() => data.nodeShop)}
                dataMenu={getSafe(() => data.allMenuLinkContentMenuLinkContent, [])}
                siteSettings={getSafe(() => data.nodeSiteSettings, [])}
                pageContext={getSafe(() => this.props.pageContext)}
                newsLetterConfig={newsLetterConfig}
                allNewsletterFormSignUpCopy={allNewsletterFormSignUpCopy}
                regions={regions}
                disableTheAgeGate={getSafe(() => data.nodeContact.field_disable_the_age_gate, false)}
            >
                <SEO
                    title={title}
                    lang={langKey}
                    description={description}
                    keywords={keywords}
                    langPageRelation={getSafe(() => data.relatedNodeContactPage.edges, [])}
                />
                <section className="section has-padding-top blv-contact">
                    <div className="container">
                        <div className="columns is-multiline">
                            <div className="column is-12-tablet is-4-desktop text-column">
                                {this.renderContactText(info)}
                            </div>
                            <div className="column is-12-tablet is-7-desktop is-offset-1-desktop form-column">
                                <ContactForm form={form} />
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}

/* Notice we are only getting the regions configuration that have a title with "Region configurations"
    There are other region configurations that are needed for other purposes but the country should not be listed,
    so we are excluding it.
*/
export const pageQuery = graphql`
    query($slug: String!, $langcode: String, $pagecode: String!) {
        nodeSiteSettings(relationships: { field_language: { field_language_code: { eq: $langcode } } }) {
            ...SiteSettingsSomeItems
        }
        nodeNewsletterForm(relationships: { field_language: { field_language_code: { eq: $langcode } } }) {
            ...NewsletterItems
        }
        allNewsletterFormSignUpCopy: allNodeNewsletterForm {
            edges {
                node {
                    field_signing_up_copy_2 {
                        value
                    }
                    field_signing_up_copy_block_2 {
                        value
                    }
                    relationships {
                        field_language {
                            field_language_code
                        }
                    }
                    field_display_sign_up_checkbox
                }
            }
        }
        regions: allNodeRegionsConfigurations(filter: { title: { regex: "/Regions Configurations*/" } }) {
            edges {
                node {
                    relationships {
                        field_region {
                            value: field_code
                            label: field_name
                            relationships {
                                field_local {
                                    name
                                    field_language_code
                                }
                            }
                            field_legal_for_en_int {
                                value
                            }
                            field_legal_for_de_de {
                                value
                            }
                            field_legal_for_fr_fr {
                                value
                            }
                            field_legal_bottom_for_de_de {
                                value
                            }
                            field_legal_bottom_for_en_int {
                                value
                            }
                            field_legal_bottom_for_fr_fr {
                                value
                            }
                            field_display_sign_up_checkbox
                        }
                    }
                }
            }
        }
        nodeShop(relationships: { field_language: { field_language_code: { eq: $langcode } } }) {
            ...ShopItems
        }
        nodeContact(
            fields: { slug: { eq: $slug } }
            relationships: { field_language: { field_language_code: { eq: $langcode } } }
        ) {
            ...ContactItems
        }
        allMenuLinkContentMenuLinkContent(
            filter: {
                relationships: { field_language_code: { field_language_code: { eq: $langcode } } }
                enabled: { eq: true }
            }
            sort: { fields: weight, order: ASC }
        ) {
            edges {
                node {
                    weight
                    name: title
                    drupal_id
                    link {
                        uri
                    }
                    drupal_parent_menu_item
                    langcode
                    relationships {
                        field_content {
                            ...LegalMenuItems
                            ...ParagraphMenuItems
                            ...ContactMenuItems
                            ...NutritionMenuItems
                            ...ProductsMenuItems
                        }
                        field_collection_related {
                            ...CollectionMenuItems
                        }
                    }
                    menu_name
                    enabled
                    bundle
                }
            }
        }
        relatedNodeContactPage: allNodeContact(
            filter: {
                field_page_code: { eq: $pagecode }
                relationships: { field_language: { field_language_code: { ne: $langcode } } }
            }
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    path {
                        alias
                    }
                    relationships {
                        field_language {
                            field_language_code
                        }
                    }
                }
            }
        }
    }
`;

ContactTemplate.propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
};
