import cookies from 'react-cookies';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { currentDate, formatDate, timeZonedDate } from '../../utils/timeZonedDate';
import { getSafe } from '../../utils/getSafe';
import axios from 'axios';
import FieldPlaceholderTop from '../UI/FieldPlaceholderTop/FieldPlaceholderTop';
import FieldSelect from '../UI/FieldSelect/FieldSelect';
import CheckboxAnimated from '../UI/CheckboxAnimated/CheckboxAnimated';
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types';

function ContactForm({ form }) {

    const [ showFormFooter, setShowFormFooter ] = useState(true);
    const [ showFromSubmissionMessage, setShowFromSubmissionMessage] = useState(false);

    const dateFormat = cookies.load('blv-age-gate-country-date-format')
        ? cookies.load('blv-age-gate-country-date-format').toUpperCase()
        : 'DD/MM/YYYY';

    const {
        first_name,
        last_name,
        email,
        subject,
        message,
        submitTo,
        date_of_birth,
        btn_label,
        legalCopy,
        agree_get_newsletter_copy,
        submissionMessage,
        fields_required_copy,
        personal_data_copy,
        country_of_residence,
        regions,
    } = form;

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSelect = (valueLabel, e, setFieldValue) => {
        const value = getSafe(() => e.target.value, '');
        setFieldValue(valueLabel, { value });
    }


    return (
        <Formik
            initialValues={{
                email: '',
                first_name: '',
                last_name: '',
                subject: '',
                contactVia: [],
                message: '',
                date_of_birth: '',
                submitTo: '',
                agree_get_newsletter: false,
                country_of_residence: '',
                captcha_response: '',
            }}
            validationSchema={Yup.object().shape({
                email: email.isRequired
                    ? Yup.string()
                        .email(email.validMessage.value ? email.validMessage.value : email.validMessage)
                        .required(
                            email.requiredMessage.value ? email.requiredMessage.value : email.requiredMessage
                        )
                    : Yup.string().email(email.validMessage.value ? email.validMessage.value : email.validMessage),
                first_name: first_name.isRequired
                    ? Yup.string().required(
                        first_name.requiredMessage.value
                            ? first_name.requiredMessage.value
                            : first_name.requiredMessage
                    )
                    : Yup.string(),
                last_name: last_name.isRequired
                    ? Yup.string().required(
                        last_name.requiredMessage.value
                            ? last_name.requiredMessage.value
                            : last_name.requiredMessage
                    )
                    : Yup.string(),
                country_of_residence: country_of_residence.isRequired
                    ? Yup.object().required(
                        country_of_residence.requiredMessage.value
                            ? country_of_residence.requiredMessage.value
                            : country_of_residence.requiredMessage
                    )
                    : Yup.string(),
                subject: subject.isRequired
                    ? Yup.string().required(
                        subject.requiredMessage.value ? subject.requiredMessage.value : subject.requiredMessage
                    )
                    : Yup.string(),
                date_of_birth: date_of_birth.isRequired
                    ? Yup.string()
                        .required(
                            date_of_birth.requiredMessage.value
                                ? date_of_birth.requiredMessage.value
                                : date_of_birth.requiredMessage
                        )
                        .test('test-date-format', '', function(value) {
                            const { path, createError } = this;
                            const dayjsAge = timeZonedDate({ date: value, format: dateFormat });
                            const now = timeZonedDate({ date: currentDate(), format: dateFormat });
                            const difference = now.diff(dayjsAge, 'hours');
                            /* Checking the date.
                              No more than 120 years and not allowing dates after today.
                            */
                            if (difference > 120 * 365 * 24 || difference < 0) {
                                return createError({
                                    path,
                                    message: getSafe(() => date_of_birth.validMessage.value, ''),
                                });
                            }
                            return dayjsAge.isValid()
                                ? true
                                : createError({
                                    path,
                                    message: getSafe(() => date_of_birth.validMessage.value, ''),
                                });
                        })
                    : Yup.string(),
                message: message.isRequired
                    ? Yup.string().required(
                        message.requiredMessage.value ? message.requiredMessage.value : message.requiredMessage
                    )
                    : Yup.string(),
                submitTo: submitTo.isRequired
                    ? Yup.object().required(
                        submitTo.requiredMessage.value ? submitTo.requiredMessage.value : submitTo.requiredMessage
                    )
                    : Yup.string(),
                captcha_response: Yup.string(),
            })}
            onSubmit={(values, { resetForm, setSubmitting }) => {
                const submit_request_to = values.submitTo.value;
                //const country = cookies.load('blv-age-gate-country');
                const country = values.country_of_residence.value;

                const countryLegalAge = cookies.load('blv-age-gate-country-legal-age');
                const today = timeZonedDate({ date: currentDate(), format: dateFormat });
                const momentAge = timeZonedDate({ date: values.date_of_birth, format: dateFormat });
                const age = today.diff(momentAge, 'years');
                const date_of_birth_formatted = formatDate({
                    date: values.date_of_birth,
                    initialFormat: dateFormat,
                    toFormat: 'YYYY/MM/DD',
                });

                const optin = values.agree_get_newsletter;

                setShowFormFooter(false);

                console.log('submitting contact form');

                if (!executeRecaptcha) {
                    console.log('Recaptcha has not been loaded');

                    return;
                }

                console.log(executeRecaptcha, typeof executeRecaptcha);

                const tt = executeRecaptcha('contact');
                console.log(tt, typeof tt);

                tt.then((token) => {
                    console.log('contact token', token);
                    axios
                        .post(
                            process.env.GATSBY_DRUPAL_CONTACT_WEBFORM_SUBMIT_ENDPOINT,
                            {
                                webform_id: 'belvedere_contact',
                                data_source: 'Website',
                                source_type: 'Contact Form',
                                ...values,
                                submit_request_to,
                                date_of_birth: date_of_birth_formatted,
                                location_of_residence: country,
                                market: country,
                                optiniagree: optin,
                                optinRelational: optin, //deprecated, must be number
                                optinemailchannel: optin,
                                optinrelational_4: optin ? 1 : 0,
                                legal_age: (age >= countryLegalAge).toString(),
                                captcha_response: token,
                            },
                            {
                                auth: {
                                    username: process.env.GATSBY_DRUPAL_USER_NAME,
                                    password: process.env.GATSBY_DRUPAL_USER_PASS,
                                },
                            }
                        )
                        .then(() => {
                            setShowFromSubmissionMessage(true);
                            resetForm();
                        })
                        .catch(error => {
                            setShowFormFooter(true);
                            console.log(error);
                            alert('Oops, something went wrong. Please try again.');
                            setSubmitting(false);
                        });
                })
                    .catch(error => {
                        console.log('contact recaptcha error', error);
                    });
            }}
        >
            {({ values, handleChange, errors, touched, handleBlur, isSubmitting, setFieldValue }) => {
                return (
                    <Form>
                        <div className="columns">
                            <div className="column is-12 is-relative">
                                <div className="input-container">
                                    <FieldPlaceholderTop
                                        attributes={{
                                            inputName: 'first_name',
                                            handleChange,
                                            handleBlur,
                                            values,
                                            type: 'text',
                                            placeholder: getSafe(() => first_name.placeholder),
                                            touched,
                                            errors,
                                            isRequired: first_name.isRequired,
                                            prefix: 'contact_',
                                        }}
                                    />
                                </div>
                                <div className="input-container">
                                    <FieldPlaceholderTop
                                        attributes={{
                                            inputName: 'last_name',
                                            handleChange,
                                            handleBlur,
                                            values,
                                            type: 'text',
                                            placeholder: getSafe(() => last_name.placeholder),
                                            touched,
                                            errors,
                                            isRequired: last_name.isRequired,
                                            prefix: 'contact_',
                                        }}
                                    />
                                </div>
                                <div className="input-container">
                                    <FieldPlaceholderTop
                                        attributes={{
                                            inputName: 'email',
                                            handleChange,
                                            handleBlur,
                                            values,
                                            type: 'text',
                                            placeholder: getSafe(() => email.placeholder),
                                            touched,
                                            errors,
                                            isRequired: email.isRequired,
                                            prefix: 'contact_',
                                        }}
                                    />
                                </div>
                                <div className="input-container">
                                    <FieldPlaceholderTop
                                        attributes={{
                                            inputName: 'date_of_birth',
                                            handleChange,
                                            handleBlur,
                                            values,
                                            type: 'text',
                                            placeholder: getSafe(() => date_of_birth.placeholder),
                                            touched,
                                            errors,
                                            isRequired: date_of_birth.isRequired,
                                            prefix: 'contact_',
                                            mask: '99/99/9999',
                                            formatField: dateFormat,
                                        }}
                                    />
                                </div>
                                <div className="input-container">
                                    <FieldSelect
                                        inputName="submitTo"
                                        handleChange={value => handleSelect('submitTo', value, setFieldValue)}
                                        values={values}
                                        touched={touched}
                                        errors={errors}
                                        field={submitTo}
                                        onBlur={handleBlur}
                                        options={
                                            submitTo.relationships
                                                ? submitTo.relationships.options
                                                : submitTo.options
                                        }
                                        page="contact_"
                                    />
                                </div>
                                <div className="input-container">
                                    <FieldSelect
                                        inputName="country_of_residence"
                                        handleChange={value =>
                                            handleSelect('country_of_residence', value, setFieldValue)
                                        }
                                        values={values}
                                        touched={touched}
                                        errors={errors}
                                        field={country_of_residence}
                                        options={regions}
                                        onBlur={handleBlur}
                                        page="contact_"
                                    />
                                </div>
                                <div className="input-container">
                                    <FieldPlaceholderTop
                                        attributes={{
                                            inputName: 'subject',
                                            handleChange,
                                            handleBlur,
                                            values,
                                            type: 'text',
                                            placeholder: getSafe(() => subject.placeholder),
                                            touched,
                                            errors,
                                            isRequired: subject.isRequired,
                                            prefix: 'contact_',
                                        }}
                                    />
                                </div>
                                <div className="input-container">
                                    <FieldPlaceholderTop
                                        attributes={{
                                            inputName: 'message',
                                            handleChange,
                                            handleBlur,
                                            values,
                                            type: 'textarea',
                                            placeholder: getSafe(() => message.placeholder),
                                            touched,
                                            errors,
                                            isRequired: message.isRequired,
                                            prefix: 'contact_',
                                        }}
                                    />
                                </div>
                                <div className="input'container">
                                    {fields_required_copy && (
                                        <div className="sign-up-input">
                                            <p className="legal-copy">
                                                    <span className="has-text-black">
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: fields_required_copy,
                                                            }}
                                                        />
                                                    </span>
                                            </p>
                                        </div>
                                    )}
                                    {personal_data_copy && (
                                        <div className="sign-up-input">
                                            <p className="form-bottom-copy">
                                                    <span className="has-text-black">
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: personal_data_copy,
                                                            }}
                                                        />
                                                    </span>
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="form-footer">
                                    <div className="agree_newsletter sign-up-input checkbox-alig-top">
                                        {agree_get_newsletter_copy && (
                                            <label>
                                                <Field name="agree_get_newsletter">
                                                    {({ field }) => {
                                                        return (
                                                            <CheckboxAnimated
                                                                name={field.name}
                                                                checked={field.value}
                                                                id="blv-checkbox-2"
                                                                {...field}
                                                            />
                                                        );
                                                    }}
                                                </Field>
                                                <span className="privacy-policy has-text-black form-bottom-copy">
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: agree_get_newsletter_copy,
                                                            }}
                                                        />
                                                    </span>
                                            </label>
                                        )}
                                    </div>
                                    <p
                                        className="legal-copy last-text"
                                        dangerouslySetInnerHTML={{
                                            __html: legalCopy,
                                        }}
                                    />
                                    <div className={`sign-up-container ${showFormFooter ? '' : 'hide'}`}>
                                        <button type="submit" className="blv-button" disabled={isSubmitting}>
                                            {btn_label}
                                        </button>
                                    </div>
                                    {showFromSubmissionMessage && (
                                        <div
                                            className={`submission-message`}
                                            dangerouslySetInnerHTML={{
                                                __html: submissionMessage,
                                            }}
                                        ></div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

ContactForm.propTypes = {
    form: PropTypes.object,
}

export default ContactForm;